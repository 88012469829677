var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ac-module-heading',[_vm._v(" Transactions Staff ")]),_c('v-card',{staticClass:"card-wrapper"},[_c('v-card-text',[_c('div',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-select',{staticClass:"select-width me-3",attrs:{"label":"Company","items":_vm.companies},model:{value:(_vm.selectedCompany),callback:function ($$v) {_vm.selectedCompany=$$v},expression:"selectedCompany"}}),_c('ac-button',{attrs:{"title":"Add Entry","icon":_vm.icons.mdiPlus}})],1),_c('v-spacer'),_c('div',{staticClass:"d-flex align-center pb-5"},[_c('ac-button',{staticClass:"me-3",attrs:{"title":"Download Ratified Approvals","icon":_vm.icons.mdiDownload}}),_c('ac-input',{staticClass:"staff-list-search me-3",attrs:{"placeholder":"Search","outlined":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1)],1),_c('div',{staticClass:"d-flex align-center flex-wrap pb-0"},[_c('ac-select',{staticClass:"select-width me-3",attrs:{"label":"Year","items":_vm.yearOPtions}}),_c('ac-select',{staticClass:"select-width me-3",attrs:{"label":"Broker","items":_vm.brokerOPtions}}),_c('ac-select',{staticClass:"multiple-select-width me-3",attrs:{"label":"Type","items":_vm.typeOPtions,"multiple":"","chips":"","deletable-chips":""}}),_c('ac-select',{staticClass:"select-width me-3",attrs:{"label":"Ratified by","items":_vm.userOPtions}}),_c('v-spacer')],1)]),_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.headers,"items":_vm.staffTransactions,"options":_vm.options,"server-items-length":_vm.totalStaffTransactions,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.broker",fn:function(ref){
var broker = ref.item.broker;
return [_c('span',[_vm._v(_vm._s(broker ? broker.number : ''))])]}},{key:"item.nearest-approval",fn:function(){return [_c('span',[_vm._v("No Approval Found")])]},proxy:true},{key:"item.follow-up",fn:function(ref){
var followUp = ref.item.followUp;
return [_c('span',[_vm._v(_vm._s(followUp ? 'Yes' : 'No'))])]}},{key:"item.file",fn:function(ref){
var file = ref.item.file;
return [(file)?_c('ac-icon-btn',{attrs:{"text":"Download File","color":"info","icon":_vm.icons.mdiFileDownload}}):_vm._e()]}},{key:"item.ratified",fn:function(ref){
var ratification = ref.item.ratification;
return [_c('span',[_vm._v(_vm._s(ratification ? ratification.date : ''))])]}},{key:"item.ratified-by",fn:function(ref){
var ratification = ref.item.ratification;
return [_c('span',[_vm._v(_vm._s(ratification ? ratification.ratifiedFullname : ''))])]}},{key:"item.actions",fn:function(){return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('ac-icon-btn',{attrs:{"btn-class":"me-1","color":"success","text":"View","icon":_vm.icons.mdiEye}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","color":"primary","text":"Ratify Upload","icon":_vm.icons.mdiDraw}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","color":"secondary","text":"Ratify Approval","icon":_vm.icons.mdiClipboardCheck}}),_c('ac-icon-btn',{attrs:{"btn-class":"me-1","color":"","text":"Delete","color":"error","icon":_vm.icons.mdiDeleteOutline}})],1)]},proxy:true}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }