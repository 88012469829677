<template>
  <div>
    <ac-module-heading> Transactions Staff </ac-module-heading>
    <v-card class="card-wrapper">
      <v-card-text>
        <div class="d-flex align-center flex-wrap pb-0">
          <div class="d-flex align-center pb-5">
            <ac-select
              label="Company"
              v-model="selectedCompany"
              :items="companies"
              class="select-width me-3"
            />
            <ac-button title="Add Entry" :icon="icons.mdiPlus" />
          </div>
          <v-spacer />
          <div class="d-flex align-center pb-5">
            <ac-button title="Download Ratified Approvals" :icon="icons.mdiDownload" class="me-3" />
            <ac-input
              v-model="searchQuery"
              placeholder="Search"
              outlined
              class="staff-list-search me-3"
            />
          </div>
        </div>
        <div class="d-flex align-center flex-wrap pb-0">
          <ac-select label="Year" :items="yearOPtions" class="select-width me-3" />
          <ac-select label="Broker" :items="brokerOPtions" class="select-width me-3" />
          <ac-select
            label="Type"
            :items="typeOPtions"
            multiple
            chips
            deletable-chips
            class="multiple-select-width me-3"
          />
          <ac-select label="Ratified by" :items="userOPtions" class="select-width me-3" />
          <v-spacer />
        </div>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="staffTransactions"
        :options.sync="options"
        :server-items-length="totalStaffTransactions"
        :loading="loading"
        class="text-no-wrap"
      >
        <template #[`item.broker`]="{ item: { broker } }">
          <span>{{ broker ? broker.number : '' }}</span>
        </template>

        <template #[`item.nearest-approval`]>
          <span>No Approval Found</span>
        </template>

        <template #[`item.follow-up`]="{ item: { followUp } }">
          <span>{{ followUp ? 'Yes' : 'No' }}</span>
        </template>

        <template #[`item.file`]="{ item: { file } }">
          <ac-icon-btn
            v-if="file"
            text="Download File"
            color="info"
            :icon="icons.mdiFileDownload"
          />
        </template>

        <template #[`item.ratified`]="{ item: { ratification } }">
          <span>{{ ratification ? ratification.date : '' }}</span>
        </template>

        <template #[`item.ratified-by`]="{ item: { ratification } }">
          <span>{{ ratification ? ratification.ratifiedFullname : '' }}</span>
        </template>

        <template #[`item.actions`]>
          <div class="d-flex align-center justify-center">
            <ac-icon-btn btn-class="me-1" color="success" text="View" :icon="icons.mdiEye" />
            <ac-icon-btn
              btn-class="me-1"
              color="primary"
              text="Ratify Upload"
              :icon="icons.mdiDraw"
            />
            <ac-icon-btn
              btn-class="me-1"
              color="secondary"
              text="Ratify Approval"
              :icon="icons.mdiClipboardCheck"
            />
            <ac-icon-btn
              btn-class="me-1"
              color=""
              text="Delete"
              color="error"
              :icon="icons.mdiDeleteOutline"
            />
          </div>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import AcButton from '@/components/AcButton.vue'
import AcInput from '@/components/AcInput.vue'
import AcSelect from '@/components/AcSelect.vue'
import AcIconBtn from '@/components/AcIconBtn.vue'
import store from '@/store'
import padealingStoreModule from '@/store/modules/pa-dealing'
import {
  mdiClipboardCheck,
  mdiDeleteOutline,
  mdiDownload,
  mdiDraw,
  mdiEye,
  mdiFileDownload,
  mdiPlus,
} from '@mdi/js'
import { onUnmounted, ref } from '@vue/composition-api'
import useStaffTransactions from './composition-apis/transactions-staff'

export default {
  components: {
    AcButton,
    AcSelect,
    AcInput,
    AcIconBtn,
  },
  setup() {
    const PA_DEALING_STORE_MODULE_NAME = 'pa-dealing'

    // Register module
    if (!store.hasModule(PA_DEALING_STORE_MODULE_NAME)) {
      store.registerModule(PA_DEALING_STORE_MODULE_NAME, padealingStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PA_DEALING_STORE_MODULE_NAME))
        store.unregisterModule(PA_DEALING_STORE_MODULE_NAME)
    })

    const {
      headers,
      options,
      staffTransactions,
      totalStaffTransactions,
      searchQuery,
      companies,
      selectedCompany,
      loading,
    } = useStaffTransactions()

    const yearOPtions = ref(['All', '2022', '2021', '2020', '2019', '2018'])
    const brokerOPtions = ref(['All', 'broker 1', 'broker 2'])
    const typeOPtions = ref(['Type 1', 'Type 2'])
    const userOPtions = ref(['User 1', 'User 2'])

    return {
      headers,
      options,
      staffTransactions,
      totalStaffTransactions,
      searchQuery,
      companies,
      selectedCompany,
      loading,
      yearOPtions,
      brokerOPtions,
      typeOPtions,
      userOPtions,
      icons: {
        mdiClipboardCheck,
        mdiDeleteOutline,
        mdiDownload,
        mdiDraw,
        mdiEye,
        mdiFileDownload,
        mdiPlus,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  .select-width {
    max-width: 16rem;
  }
  .multiple-select-width {
    min-width: 16rem;
    max-width: 20rem;
  }
  .staff-list-search {
    max-width: 12rem;
  }
}
</style>
