import store from '@/store'
import { ref, watch } from '@vue/composition-api'

export default function useStaffTransactions() {
  const staffTransactions = ref([])
  const totalStaffTransactions = ref(0)
  const loading = ref(false)
  const searchQuery = ref('')
  const isDialogVisible = ref(false)
  const companies = ref(['Company 1', 'Company 2', 'Company 3'])
  const selectedCompany = ref('')

  const headers = [
    { text: 'User', value: 'fullName' },
    { text: "User's Broker Account", value: 'broker' },
    { text: 'Security', value: 'security' },
    { text: 'Buy/Sell', value: 'details' },
    { text: 'Ticker', value: 'symbol' },
    { text: 'Exchange', value: 'exchange' },
    { text: 'Investment Class', value: 'investmentClass' },
    { text: 'Execution Date', value: 'date' },
    { text: 'Nearest Approval', value: 'nearest-approval' },
    { text: 'Comments', value: 'comments' },
    { text: 'Follow-up transaction', value: 'follow-up' },
    { text: 'File', value: 'file' },
    { text: 'Ratified', value: 'ratified' },
    { text: 'Ratified By', value: 'ratified-by' },
    {
      text: 'Actions',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref({
    sortBy: ['id'],
    sortDesc: [true],
  })

  const fetchStaffTransactions = () => {
    store
      .dispatch('pa-dealing/fetchStaffTransactions', {
        q: searchQuery.value,
        options: options.value,
      })
      .then(data => {
        staffTransactions.value = data.docs
        totalStaffTransactions.value = data.totalItems

        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })
  }

  watch([searchQuery, options], () => {
    loading.value = true
    fetchStaffTransactions()
  })

  return {
    headers,
    options,
    staffTransactions,
    totalStaffTransactions,
    isDialogVisible,
    companies,
    selectedCompany,
    searchQuery,
    loading,
  }
}
